var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-modal",
        {
          staticClass: "send-task",
          attrs: {
            width: "38%",
            height: "auto",
            title: _vm.title,
            "show-zoom": true,
            resize: "",
            "lock-view": false,
            mask: true,
            position: _vm.position,
            transfer: "",
          },
          on: {
            close: _vm.handleCloseTaskModal,
            hide: _vm.handleCloseTaskModal,
          },
          model: {
            value: _vm.ModalVisible,
            callback: function ($$v) {
              _vm.ModalVisible = $$v
            },
            expression: "ModalVisible",
          },
        },
        [
          _c("a-spin", { attrs: { spinning: _vm.spinning } }, [
            _vm.spinning
              ? _c("div", { staticClass: "spin-content" }, [
                  _vm._v(" " + _vm._s(_vm.tips) + " "),
                ])
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "a-form-model",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      layout: "horizontal",
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol,
                    },
                  },
                  [
                    _vm.form.schedulerType !== 3
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "模板名称", prop: "taskName" } },
                          [
                            _c(
                              "a-select",
                              {
                                attrs: {
                                  "show-search": "",
                                  placeholder: "支持模糊搜索选择",
                                  "default-active-first-option": false,
                                  "filter-option": false,
                                  "not-found-content": null,
                                  loading: _vm.loading,
                                },
                                on: {
                                  search: _vm.handleTaskSearch,
                                  change: _vm.handleTaskChange,
                                  focus: _vm.handleTaskSearch2,
                                },
                                model: {
                                  value: _vm.form.vehicleTaskId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vehicleTaskId", $$v)
                                  },
                                  expression: "form.vehicleTaskId",
                                },
                              },
                              _vm._l(_vm.taskArray, function (d) {
                                return _c("a-select-option", { key: d.id }, [
                                  _vm._v(" " + _vm._s(d.taskName) + " "),
                                ])
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.taskName && _vm.form.schedulerType !== 3
                      ? [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "任务类型", prop: "taskType" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getTaskTypeName())),
                              ]),
                            ]
                          ),
                          ["0", "2"].indexOf(_vm.form.taskType) >= 0
                            ? [
                                _c(
                                  "a-form-model-item",
                                  { attrs: { label: "地图ID", prop: "mapId" } },
                                  [_c("span", [_vm._v(_vm._s(_vm.form.mapId))])]
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "地图版本",
                                      prop: "mapVersion",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.form.mapVersion)),
                                    ]),
                                  ]
                                ),
                                _vm.form.taskType === "0"
                                  ? _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: "循迹路径目录名称",
                                          prop: "routeFolderName",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.form.routeFolderName)
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.form.taskType === "2"
                                  ? _c(
                                      "a-form-model-item",
                                      { attrs: { label: "经度" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.form.lon)),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.form.taskType === "2"
                                  ? _c(
                                      "a-form-model-item",
                                      { attrs: { label: "纬度" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.form.lat)),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "选择路线",
                                    prop: "taskRouteId",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.form.routeName)),
                                  ]),
                                ]
                              ),
                        ]
                      : _vm._e(),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "任务周期", prop: "schedulerType" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            on: { change: _vm.handleTaskPeriodChange },
                            model: {
                              value: _vm.form.schedulerType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "schedulerType", $$v)
                              },
                              expression: "form.schedulerType",
                            },
                          },
                          _vm._l(_vm.schedulerTypeOptions, function (d, index) {
                            return _c(
                              "a-radio-button",
                              { key: index, attrs: { value: d.dictValue } },
                              [_vm._v(_vm._s(d.dictLabel))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    [0, 1].indexOf(_vm.form.schedulerType) >= 0
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "开始时间", prop: "startTime" } },
                          [
                            _c("a-date-picker", {
                              attrs: {
                                "show-time": "",
                                placeholder: "选择时间",
                                "value-format": "YYYY-MM-DD HH:mm:ss",
                                format: "YYYY-MM-DD HH:mm:ss",
                                "disabled-date": _vm.disabledDate,
                                "disabled-time": _vm.disabledDateTime,
                              },
                              model: {
                                value: _vm.form.startTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "startTime", $$v)
                                },
                                expression: "form.startTime",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    [0, 1].indexOf(_vm.form.schedulerType) >= 0
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "任务有效期",
                              prop: "expiryDateTime",
                            },
                          },
                          [
                            _c("a-date-picker", {
                              attrs: {
                                "show-time": "",
                                placeholder: "选择时间",
                                "value-format": "YYYY-MM-DD HH:mm:ss",
                                format: "YYYY-MM-DD HH:mm:ss",
                                "disabled-date": _vm.disabledDate,
                                "disabled-time": _vm.disabledEndDateTime,
                              },
                              model: {
                                value: _vm.form.expiryDateTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "expiryDateTime", $$v)
                                },
                                expression: "form.expiryDateTime",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.schedulerType === 3
                      ? [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "地图ID" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.info && _vm.info.businessState
                                      ? _vm.info.businessState.map_id
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "地图版本" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.info && _vm.info.businessState
                                      ? _vm.info.businessState.map_version
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "目标清洁点位置" } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleMapDraw },
                                },
                                [_vm._v("去地图选点")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "目标清洁点位置经度",
                                prop: "lon",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入经度",
                                },
                                model: {
                                  value: _vm.form.lon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "lon", $$v)
                                  },
                                  expression: "form.lon",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "目标清洁点位置纬度",
                                prop: "lat",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入纬度",
                                },
                                model: {
                                  value: _vm.form.lat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "lat", $$v)
                                  },
                                  expression: "form.lat",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.form.schedulerType !== 3
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "任务备注", prop: "remark" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.form.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "remark", $$v)
                                },
                                expression: "form.remark",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _c("dialog-bottom-bar", {
                  attrs: { "primary-text": "确定", "second-text": "取消" },
                  on: {
                    onConfirm: _vm.submitForm,
                    onCancel: _vm.handleCancelTask,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("GetLocationOnMap", {
        ref: "mapRef",
        on: { onLocation: _vm.handleGetLocation },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
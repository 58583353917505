<template>
  <!-- 新增执行任务 -->
  <div>
    <vxe-modal
      v-model="ModalVisible"
      width="38%"
      height="auto"
      :title="title"
      :show-zoom="true"
      resize
      :lock-view="false"
      :mask="true"
      :position="position"
      transfer
      @close="handleCloseTaskModal"
      @hide="handleCloseTaskModal"
      class="send-task"
    >
      <a-spin :spinning="spinning">
        <div class="spin-content" v-if="spinning">
          {{ tips }}
        </div>
        <div>
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            layout="horizontal"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item v-if="form.schedulerType !== 3" label="模板名称" prop="taskName">
              <a-select
                show-search
                placeholder="支持模糊搜索选择"
                :default-active-first-option="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleTaskSearch"
                @change="handleTaskChange"
                @focus="handleTaskSearch2"
                :loading="loading"
                v-model="form.vehicleTaskId"
              >
                <a-select-option v-for="d in taskArray" :key="d.id">
                  {{ d.taskName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <template v-if="form.taskName && form.schedulerType !== 3">
              <a-form-model-item label="任务类型" prop="taskType">
                <span>{{ getTaskTypeName() }}</span>
              </a-form-model-item>
              <!-- 循迹任务 -->
              <template v-if="['0', '2'].indexOf(form.taskType) >= 0">
                <a-form-model-item label="地图ID" prop="mapId">
                  <span>{{ form.mapId }}</span>
                </a-form-model-item>
                <a-form-model-item label="地图版本" prop="mapVersion">
                  <span>{{ form.mapVersion }}</span>
                </a-form-model-item>
                <a-form-model-item label="循迹路径目录名称" prop="routeFolderName" v-if="form.taskType === '0'">
                  <span>{{ form.routeFolderName }}</span>
                </a-form-model-item>
                <a-form-model-item label="经度" v-if="form.taskType === '2'">
                  <span>{{ form.lon }}</span>
                </a-form-model-item>
                <a-form-model-item label="纬度" v-if="form.taskType === '2'">
                  <span>{{ form.lat }}</span>
                </a-form-model-item>
              </template>
              <a-form-model-item v-else label="选择路线" prop="taskRouteId">
                <span>{{ form.routeName }}</span>
              </a-form-model-item>
            </template>
            <a-form-model-item label="任务周期" prop="schedulerType">
              <a-radio-group v-model="form.schedulerType" button-style="solid" @change="handleTaskPeriodChange">
                <a-radio-button v-for="(d, index) in schedulerTypeOptions" :key="index" :value="d.dictValue">{{
                  d.dictLabel
                }}</a-radio-button>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="开始时间" prop="startTime" v-if="[0, 1].indexOf(form.schedulerType) >= 0">
              <a-date-picker
                v-model="form.startTime"
                show-time
                placeholder="选择时间"
                value-format="YYYY-MM-DD HH:mm:ss"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate"
                :disabled-time="disabledDateTime"
              />
            </a-form-model-item>
            <a-form-model-item label="任务有效期" prop="expiryDateTime" v-if="[0, 1].indexOf(form.schedulerType) >= 0">
              <a-date-picker
                v-model="form.expiryDateTime"
                show-time
                placeholder="选择时间"
                value-format="YYYY-MM-DD HH:mm:ss"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate"
                :disabled-time="disabledEndDateTime"
              />
            </a-form-model-item>
            <template v-if="form.schedulerType === 3">
              <a-form-model-item label="地图ID">
                <span>{{ info && info.businessState ? info.businessState.map_id : '-' }}</span>
              </a-form-model-item>
              <a-form-model-item label="地图版本">
                <span>{{ info && info.businessState ? info.businessState.map_version : '-' }}</span>
              </a-form-model-item>
              <a-form-model-item label="目标清洁点位置">
                <a-button @click="handleMapDraw" type="primary">去地图选点</a-button>
              </a-form-model-item>
              <a-form-model-item label="目标清洁点位置经度" prop="lon">
                <a-input type="number" v-model="form.lon" placeholder="请输入经度" />
              </a-form-model-item>
              <a-form-model-item label="目标清洁点位置纬度" prop="lat">
                <a-input type="number" v-model="form.lat" placeholder="请输入纬度" />
              </a-form-model-item>
            </template>
            <a-form-model-item label="任务备注" prop="remark" v-if="form.schedulerType !== 3">
              <a-input v-model="form.remark" placeholder="请输入" />
            </a-form-model-item>
          </a-form-model>
          <dialog-bottom-bar
            primary-text="确定"
            second-text="取消"
            @onConfirm="submitForm"
            @onCancel="handleCancelTask"
          />
        </div>
      </a-spin>
    </vxe-modal>
    <GetLocationOnMap @onLocation="handleGetLocation" ref="mapRef" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { listRoutePlanning } from '@/api/iot/routePlanning'
import { listVehicleTask, checkTaskConflict } from '@/api/iot/vehicleTask'
import {
  addTaskScheduler,
  updateTaskScheduler,
  getTaskScheduler,
  taskStartDynamic,
  taskStartAck
} from '@/api/iot/taskScheduler'
import moment from 'moment'
import DialogBottomBar from '@/components/DialogBottomBar'
import TaskBase from './TaskBase.js'
import GetLocationOnMap from './GetLocationOnMap'

export default {
  mixins: [TaskBase],
  name: 'CreateExecTaskModal',
  components: { DialogBottomBar, GetLocationOnMap },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    isShowModal: {
      type: Boolean,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: () => {}
    },
    taskForm: {
      type: Object,
      default: null
    },
    taskTemplateForm: {
      type: Object,
      default: null
    },
    isTimedTask: {
      type: Boolean,
      default: () => {}
    },
    withDynamic: {
      type: Boolean,
      default: () => {}
    }
  },
  data() {
    // 节流控制
    this.handleRouteSearch = debounce(this.handleRouteSearch, 500)
    this.handleTaskSearch = debounce(this.handleTaskSearch, 500)
    return {
      ModalVisible: this.isShowModal,
      confirmDisabled: true,
      form: {
        id: null,
        vehicleId: null,
        taskName: null,
        startTime: null, // 开始日期时间
        expiryDateTime: null,
        taskRouteId: '',
        remark: null,
        taskConflict: null,
        taskType: '1',
        schedulerType: 2,
        lon: null,
        lat: null
      },
      rules: {
        taskName: [{ required: true, message: '模板名称不能为空', trigger: 'change' }],
        startTime: [{ required: true, message: '开始时间不能为空', trigger: 'change' }],
        lon: [{ required: true, message: '目标清洁点经度不能为空', trigger: 'change' }],
        lat: [{ required: true, message: '目标清洁点纬度不能为空', trigger: 'change' }]
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      routeArray: [],
      loading: false,
      title: this.vehicleId + '-创建任务',
      spinning: false,
      vehicleTaskConflicts: [],
      tips: '正在下发任务',
      isEdit: false,
      // 任务类型
      taskTypeOptions: [
        {
          dictLabel: 'LaneId任务',
          dictValue: '1'
        },
        {
          dictLabel: '循迹任务',
          dictValue: '0'
        }
        // {
        //   dictLabel: '固定点任务',
        //   dictValue: '2'
        // }
      ],
      // 任务周期
      schedulerTypeOptions: [
        {
          dictLabel: '手动任务',
          dictValue: 2
        },
        {
          dictLabel: '单次任务',
          dictValue: 0
        },
        {
          dictLabel: '每日任务',
          dictValue: 1
        }
        // {
        //   dictLabel: '动态点位任务',
        //   dictValue: 3
        // }
      ],
      timeOpen: false,
      taskArray: [],
      isExit: false
    }
  },
  created() {
    this.getDictData()
    this.setDynamicData()
  },
  mounted() {
    if (this.taskForm) {
      this.initTaskForm(this.taskForm)
    }
    if (this.taskTemplateForm) {
      this.initTaskTemplateForm(this.taskTemplateForm)
    }
  },
  destroyed() {
    console.log('send task destroyed')
    this.clearTimeoutEx()
  },
  watch: {
    'info.vehicleId'(newVal, oldVal) {
      console.log('创建任务窗口关闭', newVal + '=' + oldVal)
      this.$emit('closeTaskModal')
      this.ModalVisible = false
    },
    taskForm(newVal, oldVal) {
      console.log('taskForm', newVal)
      this.initTaskForm(newVal)
    },
    taskTemplateForm(newVal, oldVal) {
      this.initTaskTemplateForm(newVal)
    }
  },
  methods: {
    setDynamicData() {
      if (this.withDynamic) {
        this.taskTypeOptions.push({
          dictLabel: '固定点任务',
          dictValue: '2'
        })
        this.schedulerTypeOptions.push({
          dictLabel: '动态点位任务',
          dictValue: 3
        })
      }
    },
    handleGetLocation(res) {
      console.log('handleGetLocation')
      this.form.lon = res.lng
      this.form.lat = res.lat
      this.$refs.form.clearValidate()
    },
    handleMapDraw() {
      console.log('handleMapDraw')
      this.$refs.mapRef.handleUpdate(this.form.lon * 1, this.form.lat * 1)
    },
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().add(-1, 'days').endOf('day')
    },
    disabledDateTime() {
      console.log('disabledDateTime', this.form.startTime)
      const curTime = moment().format('YYYY-MM-DD HH:mm:ss')
      if (this.form.startTime <= curTime) {
        const dt = new Date()
        const h = dt.getHours()
        const m = dt.getMinutes()
        const s = dt.getSeconds()
        return {
          disabledHours: () => this.range(0, 24).splice(0, h),
          disabledMinutes: () => this.range(0, m),
          disabledSeconds: () => [0, s]
        }
      }
    },
    disabledEndDateTime() {
      console.log('disabledDateTime', this.form.startTime)
      const startDateTime = moment(this.form.startTime)
      if (this.form.expiryDateTime <= this.form.startTime) {
        const h = startDateTime.hours()
        const m = startDateTime.minutes() + 1
        const s = startDateTime.seconds()
        return {
          disabledHours: () => this.range(0, 24).splice(0, h),
          disabledMinutes: () => this.range(0, m),
          disabledSeconds: () => [0, s]
        }
      }
    },
    getDictData() {
      // 车端回传结果状态
      this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
    },
    getTaskTypeName() {
      return this.selectDictLabel(this.taskTypeOptions, this.form.taskType)
    },
    handleTimeClose() {
      this.timeOpen = false
    },
    handleTaskTypeChange(e) {
      this.form.taskType = e.target.value
      this.$refs.form.clearValidate()
    },
    handleTaskPeriodChange(e) {
      this.form.schedulerType = e.target.value
      this.$refs.form.clearValidate()
    },
    clearFormFields() {
      this.form.id = null
      this.form.startTime = null
      this.form.createTime = null
      this.form.updateTime = null
      this.form.createBy = null
      this.form.expiryDateTime = null
      this.form.remark = null
      this.form.vehicleCreate = null
    },
    initTaskForm(form) {
      this.isEdit = true
      this.title = this.vehicleId + '-' + (this.isTimedTask ? '新增执行任务' : '修改执行任务')
      console.log('taskForm', form)
      if (!form.id) {
        this.form.taskType = null
        return
      }
      getTaskScheduler(form.id).then((response) => {
        this.form = response.vehicleTask
        for (const key in response.data) {
          if (response.data[key] !== null) {
            this.form[key] = response.data[key]
          }
        }
        if (this.isTimedTask) {
          this.clearFormFields()
        }
        if (response.routePlanning) {
          const routePlanning = response.routePlanning
          this.form.routeName = routePlanning.routeName
        }
        this.taskArray = [
          {
            id: this.form.vehicleTaskId,
            taskName: this.form.taskName
          }
        ]
        this.loading = false
        console.log('form', this.form)
        console.log('routerArray', this.routeArray)
      })
    },
    initTaskTemplateForm(templateForm) {
      // 基于任务模板创建执行任务
      this.form.mapId = templateForm.mapId
      this.form.mapVersion = templateForm.mapVersion
      this.form.routeFolderName = templateForm.routeFolderName
      this.form.taskRouteId = templateForm.taskRouteId
      this.form.taskType = templateForm.taskType
      this.form.vehicleTaskId = templateForm.id
      this.taskArray = [
        {
          id: templateForm.id,
          taskName: templateForm.taskName
        }
      ]
      this.form.taskName = templateForm.taskName
      this.form.routeName = templateForm.routeName
    },
    doCheckTaskConflict() {
      const { vehicleId } = this
      const { startTime, taskRouteId } = this.form
      if (!vehicleId || !startTime || !taskRouteId) {
        return
      }
      const params = {
        vehicleId: vehicleId,
        startTime: startTime,
        taskRouteId: taskRouteId,
        taskType: this.form.taskType
      }
      this.loading = true
      checkTaskConflict(params).then((response) => {
        this.taskExpectTime = response.taskExpectTime

        this.vehicleTaskConflicts = response.vehicleTaskConflicts
        this.confirmDisabled = this.vehicleTaskConflicts.length > 0
        if (this.vehicleTaskConflicts.length === 0) {
          this.form.taskConflict = '无'
        }
        this.loading = false
        this.$forceUpdate()
      })
    },
    handleStartTimeConfirm(value) {
      console.log('startTime: ', value)
      this.form.startTime = value
      // this.doCheckTaskConflict()
      // 开始时间改变
    },
    handleTimeChange(value, dateString) {
      // console.log('Selected Time: ', value)
      // console.log('Formatted Selected Time: ', dateString)
      // this.form.startTime = value
      // this.doCheckTaskConflict()
    },
    handleRouteSearch(value) {
      console.log('handleLaneSearch', value)
      const queryParam = {
        routeName: value
      }
      this.loading = true
      listRoutePlanning(queryParam).then((response) => {
        this.routeArray = response.rows
        this.loading = false
      })
    },
    // 路线改变
    handleRouteChange(value) {
      this.form.taskRouteId = value
      console.log('handleRouteChange', value)
      // this.doCheckTaskConflict()

      this.$emit('viewRoute', value)
    },
    handleCloseTaskModal() {
      this.isExit = true
      this.clearTimeoutEx()
      this.$emit('closeTaskModal')
    },
    handleCancelTask() {
      this.$emit('closeTaskModal')
      this.ModalVisible = false
    },
    /** 提交按钮 */
    async submitForm() {
      await this.beforeSubmitHandle(this.form, 'isSumbit')
      this.form.vehicleId = this.vehicleId
      console.log(this.form)
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.schedulerType === 3) {
            // 动态点位任务
            const mapId = this.info.businessState ? this.info.businessState.map_id : ''
            const mapVersion = this.info.businessState ? this.info.businessState.map_version : ''
            if (!mapId || !mapVersion || mapId === '-' || mapVersion === '-') {
              this.$warningEx('地图Id或地图版本为空！')
              return
            }
            // 坐标转换
            const form = {
              mapId: mapId,
              mapVersion: mapVersion,
              routingType: 3,
              lat: this.form.lat,
              lon: this.form.lon,
              vehicleId: this.vehicleId
            }
            this.$loading.show({
              msg: '正在执行动态点任务'
            })
            taskStartDynamic(form)
              .then((res) => {
                console.log('taskStartDynamic', res)
                // 获取动态任务操作结果
                this.taskIsRunning = true
                this.isClearTimeout = false
                this.taskAckBeginTickount = new Date()
                const params = {
                  vehicleId: this.vehicleId
                }
                this.getTaskAck(taskStartAck, params, 'start')
              })
              .catch((e) => {
                this.$loading.hide()
              })
          } else {
            if (this.isEdit && !this.isTimedTask) {
              updateTaskScheduler(this.form).then((res) => {
                this.$emit('closeTaskModal')
                this.$emit('update')
                this.$successEx('修改任务成功！')
                this.ModalVisible = false
              })
            } else {
              this.spinning = true
              // 新增任务
              addTaskScheduler(this.form)
                .then((response) => {
                  this.$emit('closeTaskModal')
                  this.$emit('update')
                  this.$successEx('新增任务成功！')
                  this.ModalVisible = false
                })
                // Promise reject时回调
                .catch((e) => {
                  this.spinning = false
                })
            }
          }
        } else {
          return false
        }
      })
    },
    toEnd(infoType, result) {
      this.$loading.hide()
      let myModal = this.$successEx
      if (infoType === 'warn') {
        myModal = this.$warningEx
      } else if (infoType === 'error') {
        myModal = this.$errorEx
      }
      myModal(result, () => {
        this.clearTimeoutEx()
        this.$emit('closeTaskModal')
        this.$emit('update')
        this.ModalVisible = false
      })
    },
    clearTimeoutEx() {
      this.createTaskTimeoutId && clearTimeout(this.createTaskTimeoutId)
    },
    handleTaskSearch2() {
      if (this.taskArray.length <= 1) {
        this.handleTaskSearch(' ')
      }
    },
    handleTaskSearch(value) {
      console.log('handleTaskSearch', value)
      const queryParam = {
        taskName: value,
        vehicleId: this.vehicleId,
        status: 0,
        vehicleCreate: 0
      }
      if (value && value.trim() === '') {
        delete queryParam.taskName
        queryParam.pageNum = 1
        queryParam.pageSize = 10
      }
      this.loading = true
      listVehicleTask(queryParam).then((response) => {
        this.taskArray = response.rows
        this.loading = false
        this.$forceUpdate()
      })
    },
    handleTaskChange(value) {
      const record = this.taskArray.find((p) => p.id === value)
      if (record) {
        this.form.taskName = record.taskName
        this.form.taskType = record.taskType
        this.form.mapId = record.mapId
        this.form.mapVersion = record.mapVersion
        this.form.routeFolderName = record.routeFolderName
        this.form.routeName = record.routeName
        this.form.lon = record.lon
        this.form.lat = record.lat
      }
      this.clearFormFields()
    }
  }
}
</script>
<style lang="less">
.send-task {
  .ant-form-item {
    margin-bottom: 11.2px !important;
  }
}
.task-conflict {
  height: 25px;
}
.spin-content {
  padding: 20px;
  padding-left: 70px;
  font-weight: 600;
}
.send-task {
  .ant-spin-blur {
    opacity: 1 !important;
  }
  .ant-spin {
    left: 0 !important;
    top: 30px !important;
    height: 0 !important;
  }
  .ant-spin-dot {
    left: 50px !important;
  }
}
</style>
